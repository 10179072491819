



import { Vue } from 'vue-property-decorator';

export default class Redirect extends Vue {
  mounted(): void {
    const slug = this.$route.params.id;
    if (slug === 'bmrsb') {
      window.location.href = 'https://bluemountain.funfangle.club/reservation/sb';
      return;
    }
    if (slug === 'bmrrg') {
      window.location.href = 'https://bluemountain.funfangle.club/reservation/rg';
      return;
    }
    window.location.href = 'https://www.funfangle.com';
  }
}
